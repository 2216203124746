<template>
  <!-- item template -->

  <li>
    <div :class="{ bold: isFolder }" @click="toggle" @dblclick="makeFolder">
      {{ item.name }}
      <span v-if="isFolder">[{{ isOpen ? "-" : "+" }}]</span>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <tree-item
        class="item"
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
      ></tree-item>
      <li class="add" @click="$emit('add-item', item)">+</li>
    </ul>
  </li>

  <p>(You can double click on an item to turn it into a folder.)</p>

  <!-- the demo root element -->
  <ul id="demo">
    <tree-item
      class="item"
      :item="treeData"
      @make-folder="makeFolder"
      @add-item="addItem"
    ></tree-item>
  </ul>
</template>

<script>
import { computed } from "vue";
export default {
  name: "TestTree",

  setup() {
    let isOpen = false;
    const item = Object;
    const treeData = {
      name: "My Tree",
      children: [
        { name: "hello" },
        { name: "wat" },
        {
          name: "child folder",
          children: [
            {
              name: "child folder",
              children: [{ name: "hello" }, { name: "wat" }],
            },
            { name: "hello" },
            { name: "wat" },
            {
              name: "child folder",
              children: [{ name: "hello" }, { name: "wat" }],
            },
          ],
        },
      ],
    };

    function makeFolder(item) {
      item.children = [];
      this.addItem(item);
    }

    function addItem(item) {
      item.children.push({
        name: "new stuff",
      });
    }

    function toggle() {
      if (isFolder.value) {
        isOpen = !isOpen;
      }
    }

    const isFolder = computed(() => {
      return item.children && item.children.length;
    });

    return { makeFolder, addItem, isOpen, item, treeData, isFolder, toggle };
  },
};
</script>

<style>
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}
</style>